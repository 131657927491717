<template>
  <div class="grid gap-8">
    <div class="flex items-center justify-end gap-5">
      <new-button data-cy="back-button" @click="goPreviousPage">Back</new-button>
    </div>
    <div
      class="dark-mode-bg dark-mode-border-0 border rounded-2xl border-gray-90 bg-white p-6 pb-5"
    >
      <h4 class="mb-2 text-lg font-medium">DM sent:</h4>
      <p data-cy="dm-message-content" class="text-lg">
        {{ campaign.message }}
      </p>
    </div>
    <div>
      <div
        class="dark-mode-bg-90 dark-mode-border-0 border rounded-tl-2xl rounded-tr-2xl border-gray-90 bg-white p-6"
        data-cy="auto-dm-list"
      >
        <div v-if="autoDMLists.length > 0">
          <div class="flex justify-between pb-4">
            <div class="flex flex-col space-y-2">
              <h4 v-for="list in autoDMLists" :key="list.id" class="text-lg font-medium">
                {{ list.name }}
              </h4>
            </div>
            <div>
              <new-button @click="showEditListsModal = true">Edit lists</new-button>
            </div>
          </div>
          <p class="text-lg">{{ messagedUsersCount }}</p>
        </div>
        <div v-else>
          <div class="flex items-center justify-between">
            <p class="text-lg">{{ messagedUsersCount }}</p>
            <div>
              <new-button @click="showEditListsModal = true">Edit lists</new-button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="dark-mode-bg dark-mode-border-0 border rounded-bl-2xl rounded-br-2xl border-t-0 border-gray-90 bg-white px-6 pb-6"
        v-if="messagedUsersData.length > 0"
      >
        <table class="w-full">
          <thead>
            <tr class="dark-mode-border-50 border-b border-gray-90">
              <th class="bg-transparent px-0 py-4 text-left font-main-font text-base font-medium">
                Name
              </th>
              <th class="bg-transparent px-0 py-4 text-left font-main-font text-base font-medium">
                Handle
              </th>
              <th class="bg-transparent px-0 py-4 text-left font-main-font text-base font-medium">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            <tr data-cy="auto-dm-user-item" v-for="user in messagedUsersData" :key="user.id">
              <td class="border-0 px-0 pb-0 pt-4">
                <div class="flex items-center gap-2">
                  <img
                    :src="user.profile_image_url"
                    :alt="user.name"
                    class="h-6 w-6 rounded-full object-cover"
                  />
                  <span class="text-base">{{ user.name }}</span>
                </div>
              </td>
              <td class="border-0 px-0 pb-0 pt-4">
                <a class="text-base" :href="`https://twitter.com/${user.username}`" target="_blank"
                  >@{{ user.username }}</a
                >
              </td>
              <!-- Not Implemented -->
              <!-- <td class="px-0 pt-4 pb-0 border-0"> -->
              <!--   <button class="button tiny-button bg-success-100 text-white">Open</button> -->
              <!-- </td> -->
              <td class="border-0 px-0 pb-0 pt-4">
                <span class="cursor-default rounded-full px-4 py-2 bg-success-100 text-white"> Sent </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <new-edit-lists-modal
      :show="showEditListsModal"
      :campaignId="campaign.id"
      :selectedLists="listIds"
      :type="type"
      @close="showEditListsModal = false"
      @update-lists="updateLists"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import NewEditListsModal from '@/views/Modals/NewEditListsModal.vue';
  import lodash from 'lodash';

  export default {
    data() {
      return {
        loading: false,
        showEditListsModal: false,
        listIds: [],
      };
    },
    created() {
      const campaignLists = lodash.get(this.campaign, 'autoDM.tags', []);
      this.listIds = [...campaignLists];
    },
    components: {
      NewEditListsModal,
    },
    props: {
      campaign: {
        type: Object,
        required: true,
      },
      type: {
        type: String,
        required: true,
      },
      messagedUsersData: {
        type: Array,
        default: () => [],
      },
    },
    methods: {
      updateLists(lists) {
        this.listIds = [...lists];
      },
      goPreviousPage() {
        this.$emit('go-previous-page', 'campaigns');
      },
    },
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile' }),
      autoDMLists() {
        return this.userProfile.autoDMTags.filter((list) => {
          return this.listIds.some((id) => list.id === id);
        });
      },
      messagedUsersCount() {
        const messagedUsersCount = this.campaign.autoDM.messagedUsers
          ? this.campaign.autoDM.messagedUsers.length
          : 0;
        return `${messagedUsersCount} ${messagedUsersCount > 1 ? 'contacts' : 'contact'} messaged`;
      },
    },
  };
</script>
