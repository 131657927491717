<template>
  <div>
    <div class="flex flex-col gap-8">
      <div class="flex items-center justify-between">
        <button
          @click="disableCampaign"
          v-if="canDisableCampaign"
          class="button small-button secondary-danger"
          data-cy="disable-campaign-button"
        >
          <inline-svg src="/img/icons/close.svg" />
          <span class="text-red-500">Disable campaign</span>
        </button>
        <new-button data-cy="back-button" @click="goPreviousPage">Back</new-button>
      </div>
      <div
        class="dark-mode-bg border dark-mode-border-0 flex flex-col rounded-2xl border-gray-90 bg-white p-6 pb-5"
        data-cy="auto-dm-thread"
      >
          <div class="mb-4 flex items-center gap-x-2">
            <img
              :alt="userProfile.name"
              :src="userProfile.photoURL"
              class="avatar-img"
            />

              <span class="avatar-name">{{ userProfile.name }}</span>
              <span class="avatar-username">@{{ userProfile.username }}</span>

          </div>
          <p data-cy="dm-status-message" class="mb-4 text-lg">
            {{ thread.tweets[0].status }}
          </p>
          <p
            class="flex items-center gap-1 pb-4 text-lg text-dark-mode-15"
          >
            {{ thread.time.format('h:mm A') }}
            <span class="block h-1 w-1 rounded-full bg-dark-mode-15"></span>
            {{ thread.time.format('MMMM D, YYYY') }}
          </p>
          <hr class="divider" />
          <div class="flex items-center gap-8 pt-4">
            <span data-cy="dm-reply-count" class="flex items-center gap-2 text-dark-mode-10">
              <inline-svg src="img/icons/twitter-reply.svg" />
              {{ replyCount }}
            </span>
            <span data-cy="dm-retweet-count" class="flex items-center gap-2 text-dark-mode-10">
              <inline-svg src="img/icons/twitter-retweet.svg" />
              {{ retweetCount }}
            </span>
          </div>
      </div>
      <div
        class="dark-mode-bg border dark-mode-border-0 flex flex-col rounded-2xl border-gray-90 bg-white p-6"
      >
        <div data-cy="dm-conditions" class="flex flex-col gap-2">
          <span class="block text-lg font-medium">Criteria for auto-DM recipients:</span>
          <div class="grid gap-4" v-if="thread.autoDM.triggerOptions.includes('reply')">
            <p class="text-base font-normal">User responds to post ({{ replyCount }})</p>
          </div>
          <div class="flex items-start" v-if="thread.autoDM.triggerOptions.includes('retweet')">
            <p class="text-base font-normal">User retweets post ({{ retweetCount }})</p>
          </div>
        </div>
      </div>

      <div
        class="dark-mode-bg border dark-mode-border-0 flex flex-col rounded-2xl border-gray-90 bg-white p-6 max-w-610"
      >
        <h4 class="mb-2 text-lg font-medium">DM sent within 20m of meeting criteria:</h4>
        <div class="space-y-4">
          <div v-for="({ title, messages}, i) in getAutoDMMessages" :key="title" class="space-y-4">
            <h5 class="text-lg font-medium">{{ title }}</h5>
            <div v-for="({ reply, content }, j) in messages" :key="`reply${j}`" class="space-y-1">
              <p class="text-base font-normal" v-if="reply">
                <span class="text-dark-mode-10">Reply content:</span> {{ reply }}
              </p>
              <p class="whitespace-pre-wrap text-base font-normal">{{ content }}</p>
            </div>
            <hr class="divider" v-if="i !== getAutoDMMessages.length - 1" />
          </div>
        </div>
      </div>
      <div
        class="dark-mode-bg border dark-mode-border-0 flex flex-col rounded-2xl border-gray-90 bg-white p-6"
        v-if="shortenedUrls.length > 0"
      >
        <div class="bg-white dark-mode-bg">
          <h4 class="mb-2 text-lg font-medium">Links clicks:</h4>
          <p class="text-base font-normal" v-for="data in getLinksByClicks" :key="data.slug">{{data.link}}: {{ data.clicks }}</p>
        </div>
      </div>
      <div class="dark-mode-bg border dark-mode-border-0 rounded-2xl border-gray-90 bg-white">
        <div data-cy="auto-dm-list" class="p-6 dark-mode-bg-90 border-gray-90 border-b dark-mode-border-0" :class="[messagedUsersData.length > 0 ? 'rounded-t-2xl' : 'rounded-2xl' ]">
          <div v-if="autoDMLists.length > 0">
            <div class="flex justify-between pb-4">
              <div class="flex flex-col space-y-2">
                <h4 v-for="list in autoDMLists" :key="list.id" class="text-lg font-medium">
                  {{ list.name }}
                </h4>
              </div>
              <div>
                <!-- <new-button data-cy="edit-lists-button" @click="showEditListsModal = true">Edit lists</new-button> -->
              </div>
            </div>
            <p class="text-lg">{{ messagedUsersCount }}</p>
          </div>
          <div v-else>
            <div class="flex items-center justify-between">
              <p class="text-lg">{{ messagedUsersCount }}</p>
              <div>
                <!-- <new-button @click="showEditListsModal = true">Edit lists</new-button> -->
              </div>
            </div>
          </div>
        </div>
        <div
          class=" pb-6 px-6"
          v-if="messagedUsersData.length > 0"
        >
          <table class="w-full">
            <thead>
              <tr class="dark-mode-border-50 border-b border-gray-90">
                <th class="bg-transparent px-0 py-4 text-left font-main-font text-base font-medium">
                  Name
                </th>
                <th class="bg-transparent px-0 py-4 text-left font-main-font text-base font-medium">
                  Handle
                </th>
                <th class="bg-transparent px-0 py-4 text-left font-main-font text-base font-medium">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              <tr data-cy="auto-dm-user-item" v-for="user in messagedUsersData" :key="user.id">
                <td class="border-0 px-0 pt-4 pb-0">
                  <div class="flex items-center gap-2">
                    <img
                      :src="user.profile_image_url"
                      :alt="user.name"
                      class="h-6 w-6 rounded-full object-cover"
                    />
                    <span class="text-base">{{ user.name }}</span>
                  </div>
                </td>
                <td class="border-0 px-0 pb-0 pt-4">
                  <a class="text-base" :href="`https://twitter.com/${user.username}`" target="_blank"
                    >@{{ user.username }}</a
                  >
                </td>
                <!-- Not Implemented -->
                <!-- <td class="px-0 pt-4 pb-0 border-0"> -->
                <!--   <button class="button tiny-button bg-success-100 text-white">Open</button> -->
                <!-- </td> -->
                <td class="border-0 px-0 pt-4 pb-0">
                  <span
                    class="rounded-full px-4 py-2 cursor-default"
                    :class="
                      user.status === 'Sent'
                        ? 'bg-success-100 text-white'
                        : 'text-dark-mode-15 bg-gray-10'
                    "
                  >
                    {{ user.status }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- <new-edit-lists-modal
      :show="showEditListsModal"
      :campaignId="thread.id"
      :selectedLists="listIds"
      :type="type"
      @close="showEditListsModal = false"
      @update-lists="updateLists"
    /> -->
  </div>
</template>

<script>
  import dao from '@/dao';
  import { mapGetters } from 'vuex';
  import moment from 'moment';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  // import NewEditListsModal from '@/views/Modals/NewEditListsModal.vue';
  import { get } from 'lodash';

  export default {
    data() {
      return {
        loading: false,
        isDisabled: false,
        // showEditListsModal: false,
        listIds: [],
      };
    },
    created() {
      this.listIds = [...this.thread.autoDM.tags];
    },
    components: {
      // NewEditListsModal,
    },
    props: {
      type: {
        type: String,
        required: true,
      },
      thread: {
        type: Object,
        required: true,
      },
      messagedUsersData: {
        type: Array,
        default: () => [],
      },
      shortenedUrls: {
        type: Array,
        default: () => [],
      },
    },
    methods: {
      goPreviousPage() {
        this.$emit('go-previous-page', 'campaigns');
      },
      disableCampaign() {
        const self = this;
        self.swalModal({
          title: 'Disable campaign',
          html: `Are you sure you want to disable this DM campaign?`,
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes, go ahead',
          preConfirm: async () => {
            try {
              await dao.disableCampaign(self.thread.id);
              self.$notify({ type: 'success', message: 'Campaign disabled!' });
              self.isDisabled = true;
            } catch (error) {
              self.swalModal({
                title: 'Sorry',
                text: `An error has occurred while disabling this DM campaign.`,
                type: 'warning',
              });
            }
          },
        });
      },
      updateLists(lists) {
        this.listIds = [...lists];
      },
    },
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile' }),
      autoDMLists() {
        return this.userProfile.autoDMTags.filter((list) => {
          return this.listIds.some((id) => list.id === id);
        });
      },
      canDisableCampaign() {
        const wasStartedMoreThanThreeDaysAgo = moment(this.thread.time).isBefore(
          moment().subtract(3, 'days')
        );
        return (
          !this.isDisabled && !wasStartedMoreThanThreeDaysAgo && !this.thread.autoDM.isDisabled
        );
      },
      getLinksByClicks() {
        return this.shortenedUrls.map((link) => {
          const numberOfClicks = (() => {
            if (link.meta_data.link_metadata.length === 0) {
              return 0;
            }
            return link.meta_data.link_metadata.reduce((acc, curr) => {
              return acc + curr.number_of_clicks;
            }, 0);
          })();
          return {
            slug: link.slug,
            link:
              link.redirect_to.length <= 55
                ? link.redirect_to
                : link.redirect_to.slice(0, 55) + '...',
            clicks: numberOfClicks,
          };
        });
      },
      getAutoDMMessages() {
        const hasAllTriggers = this.thread.autoDM.triggerOptions.length === 2;
        const formatedEntries = (() => {
          if (hasAllTriggers && this.thread.autoDM.requiresOnlyOneOption) {
            return {
              'Reply or Retweet': this.thread.autoDM.messages,
            };
          } else if (hasAllTriggers) {
            return {
              'Reply & Retweet': this.thread.autoDM.messages,
            };
          } else if (this.thread.autoDM.triggerOptions.includes('retweet')) {
            return {
              'Retweet Only': this.thread.autoDM.messages,
            };
          } else if (this.thread.autoDM.triggerOptions.includes('reply')) {
            return {
              'Replies Only': this.thread.autoDM.messages,
            };
          }
        })();
        return Object.entries(formatedEntries).map(([key, value]) => {
          return {
            title: key,
            messages: value,
          };
        });
      },
      getAutoDMLists() {
        if (this.thread) {
          return this.userProfile.autoDMTags.filter((dmTag) =>
            this.thread.autoDM.tags.some((tag) => tag === dmTag.id)
          );
        }
        return null;
      },
      replyCount() {
        return this.thread.tweets[0].replyCount
          ? this.thread.tweets[0].replyCount.toLocaleString()
          : 0;
      },
      retweetCount() {
        return this.thread.tweets[0].retweetCount
          ? this.thread.tweets[0].retweetCount.toLocaleString()
          : 0;
      },
      messagedUsersCount() {
        const messagedUsersCount = this.thread.autoDM.messagedUsers
          ? this.thread.autoDM.messagedUsers.length
          : 0;
        return `${messagedUsersCount} ${messagedUsersCount > 1 ? 'contacts' : 'contact'} messaged`;
      },
    },
    mixins: [SwalModalMixin],
  };
</script>
