<template>
  <div>
    <div v-if="isSubmitting" class="overlay">
      <div class="loader fixed" style="z-index: 1000">
        <span class="loading loading-lg">
          <inline-svg src="/img/icons/loading.svg"></inline-svg>
        </span>
      </div>
    </div>
    <div v-else>
      <div
        v-if="!canViewAutoDMPage"
        class="sample-overlay pointer-events-none absolute bottom-0 left-0 right-0 top-0 bg-transparent"
      />
      <div>
        <div>
          <h1 class="mb-6">{{ pageHeader }}</h1>

          <new-alert
            class="my-4"
            v-show="
              userProfile.customerStatus === 'standard' &&
              userProfile.freeWeeklyAutoDMs &&
              userProfile.freeWeeklyAutoDMs.value === 0
            "
          >
            You have run out of free Auto-DMs for the week. It will reset in
            {{ freeAutoDMsDaysLeft }} or you can
            <upgrade-to-premium-link class="text-main-color-100 underline"
              >upgrade to {{ getPlanLabel('premium') }}</upgrade-to-premium-link
            >
            for unlimited Auto-DMs.
          </new-alert>

          <new-alert
            data-cy="autodms-subscription-alert"
            class="my-4"
            v-if="isUserPlanBasicOrBelow(userProfile.customerStatus, false)"
          >
            Auto-DMs are only available for {{ getPlanLabel('standard') }} plan and above users,
            click
            <router-link
              data-cy="autodms-upgrade-link"
              to="/billing"
              class="text-main-color-100 underline"
              >here to upgrade</router-link
            >.
          </new-alert>

          <div
            v-if="activeTab === 'campaigns' || activeTab === 'lists'"
            class="mb-10 flex flex-wrap items-center justify-between gap-y-3"
          >
            <!-- <div
              class="border dm-nav-container flex items-center gap-x-1 rounded-full border-gray-90 p-1.5 md:gap-x-4 md:p-2"
            >
              <button
                @click="activeTab = 'campaigns'"
                class="button small-button"
                :class="[
                  activeTab === 'campaigns'
                    ? ' auto-dm-nav-link bg-main-color-50 text-main-color-100'
                    : 'text-dark-mode-15',
                ]"
                data-cy="campaigns-tab"
              >
                <inline-svg src="/img/icons/dm-campaign.svg" />
                <span>Campaigns</span>
              </button>
              <button
                @click="openListTab"
                class="button small-button"
                :class="[
                  activeTab === 'lists'
                    ? 'auto-dm-nav-link bg-main-color-50 text-main-color-100'
                    : 'text-dark-mode-15',
                ]"
                data-cy="lists-tab"
              >
                <inline-svg src="/img/icons/lists.svg" />
                <span>Lists</span>
              </button>
            </div> -->
            <div class="relative" v-if="activeTab !== 'create-campaign'">
              <new-button
                data-cy="create-new-button"
                :disabled="!canViewAutoDMPage"
                @click="createNew"
              >
                Start a Campaign
              </new-button>
            </div>
          </div>
        </div>

        <div v-if="activeTab === 'campaigns' && dmCampaigns && autoDMThreads">
          <base-alert v-if="userRanOutOfDailyDMs" type="warning" class="mb-5 items-center">
            You've hit the limit of {{ autoDMLimits.successfulDMs }} Auto-DMs per day.<br />
            We're pausing the sending of DM's for you today to decrease spam signals. <br />
            Do not worry, we will resume sending Auto-DMs tomorrow. <br />
            <span v-if="nextAutoDMLimitPlan"
              >Or you can
              <a
                class="text-main-color-100 underline"
                href="javascript:;"
                @click="openAutoDMLimitNextPlanPopUp"
                >upgrade to the next plan</a
              >
              in order to send more Auto-DMs.</span
            >
          </base-alert>

          <base-alert type="warning" class="mb-5 items-center" v-if="!hasCampaigns && canViewAutoDMPage">
            You don't have any Auto-DM campaigns.
            <a href="javascript:;" @click="activeTab = 'create-campaign'">Click here to start!</a>
          </base-alert>
          <div class="grid gap-8">
            <template v-for="(threads, time) in getFormatedAutoDMThreads">
              <div v-if="threads.length > 0" :key="time">
                <h4 class="text-lg text-dark-mode-15 text-gray-80 mb-4">
                  {{ time }} ({{ threads.length }})
                </h4>
                <div class="grid gap-4">
                  <div
                    v-for="dmCampaign in threads"
                    :key="dmCampaign.id"
                    class="dark-mode-bg bg-white border border-gray-90 dark-mode-border-0 rounded-2xl p-6 relative"
                  >
                    <auto-dm-card
                      :type="'thread'"
                      :campaign="dmCampaign"
                      @openThread="canViewAutoDMPage && openThread(dmCampaign)"
                      data-cy="campaign-card"
                    />
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div v-if="activeTab === 'lists'">
          <div class="grid gap-8">
            <!-- <div -->
            <!--   class="dark-mode-bg bg-white border border-gray-90 dark-mode-border-0 rounded-2xl p-6" -->
            <!-- > -->
            <!--   <div class="flex items-center justify-between"> -->
            <!--     <div> -->
            <!--       <span class="text-dark-mode-15 text-lg block mb-2">Total Contacts</span> -->
            <!--       <div class="flex items-center gap-5"> -->
            <!--         <span class="text-2xl font-black leading-none">819</span> -->
            <!--         <span class="text-success-100 text-lg flex items-center"> -->
            <!--           <svg -->
            <!--             width="16" -->
            <!--             height="16" -->
            <!--             viewBox="0 0 16 16" -->
            <!--             fill="none" -->
            <!--             xmlns="http://www.w3.org/2000/svg" -->
            <!--             class="stroke-current" -->
            <!--           > -->
            <!--             <path -->
            <!--               d="M12.0467 6.38016L8.00004 2.3335L3.95337 6.38016" -->
            <!--               stroke-width="1.5" -->
            <!--               stroke-miterlimit="10" -->
            <!--               stroke-linecap="round" -->
            <!--               stroke-linejoin="round" -->
            <!--             /> -->
            <!--             <path -->
            <!--               d="M8 13.6668V2.44678" -->
            <!--               stroke-width="1.5" -->
            <!--               stroke-miterlimit="10" -->
            <!--               stroke-linecap="round" -->
            <!--               stroke-linejoin="round" -->
            <!--             /> -->
            <!--           </svg> -->
            <!--           19% -->
            <!--         </span> -->
            <!--       </div> -->
            <!--     </div> -->
            <!--     <div class="flex items-center gap-4"> -->
            <!--       <span class="dark-mode:bg-dark-mode-60 px-3 py-2 rounded-full bg-gray-90 text-lg" -->
            <!--         >Cumulative</span -->
            <!--       > -->
            <!--       <span class="dark-mode:bg-dark-mode-60 px-3 py-2 rounded-full bg-gray-90 text-lg" -->
            <!--         >New</span -->
            <!--       > -->
            <!--     </div> -->
            <!--   </div> -->
            <!-- </div> -->
            <div
              class="bg-white border border-gray-90 dark-mode-bg dark-mode-border-0 rounded-2xl p-6 grid gap-4 relative"
              v-for="(list, key) in lists"
              :key="key"
              data-cy="campaign-list-item"
            >
              <div class="flex items-center justify-between">
                <h4 class="font-bold text-2xl leading-none">{{ list.name }}</h4>
              </div>
              <p class="text-lg">{{ list.messagedUsers.length }} contacts</p>
              <!-- <div class="flex items-start gap-6"> -->
              <!--   <div> -->
              <!--     <span class="text-lg font-medium block">6.1%</span> -->
              <!--     <span class="text-base text-dark-mode-15 font-medium">Clicks</span> -->
              <!--   </div> -->
              <!--   <div> -->
              <!--     <span class="text-lg font-medium block">56.1%</span> -->
              <!--     <span class="text-base text-dark-mode-15 font-medium">Opens</span> -->
              <!--   </div> -->
              <!--   <div> -->
              <!--     <span class="text-lg text-dark-mode-15 font-medium block">List avg</span> -->
              <!--   </div> -->
              <!-- </div> -->
              <a href="javascript:;" @click="canViewAutoDMPage && openList(list)" class="absolute inset-0 z-10"></a>
            </div>
          </div>
        </div>
        <div v-if="showCampaign">
          <auto-dm-thread-page
            v-if="typeOfSelectedThread === 'thread'"
            :messagedUsersData="messagedUsersData"
            :shortenedUrls="shortenedUrls"
            :thread="selectedThread"
            :type="typeOfSelectedThread"
            @go-previous-page="changeTab"
          />
          <auto-dm-campaign-page
            v-if="typeOfSelectedThread === 'campaign'"
            :messagedUsersData="messagedUsersData"
            :campaign="selectedThread"
            :type="typeOfSelectedThread"
            @go-previous-page="changeTab"
          />
        </div>
        <div v-if="activeTab === 'list'">
          <auto-dm-list-page
            :listDetails="selectedListDetails"
            :messagedUsersData="messagedUsersData"
            @go-previous-page="changeTab"
          />
        </div>
        <div v-if="activeTab === 'create-campaign'">
          <div class="space-y-8">
            <div class="flex items-center justify-between gap-5">
              <h3 class="text-32 font-bold">Create a tweet that triggers the DM Campaign</h3>
              <new-button @click="activeTab = 'campaigns'">Back</new-button>
            </div>
            <div class="border w-full autodm-section-container rounded-2xl border-gray-90 p-3 sm:p-6">
              <gif-selector
                v-if="isGifSelectorVisible"
                class="h-64 overflow-auto"
                @selected-gif="addGifToTweet"
                @close-gif-selector="isGifSelectorVisible = false"
              />
              <new-composer-text-area
                v-else
                :tweet="thread"
                :canAddMoreVideos="canAddMoreVideos"
                :tweetIndexToFocus="tweetIndexToFocus"
                :isTweetBoosterDropdownEnabled="false"
                :selectedCategories="selectedCategories"
                :uploadingMedia="uploadingMedia"
                @status-updated="updateStatus"
                @add-category="addCategory"
                @remove-category="removeCategory"
                @open-gif-selector="isGifSelectorVisible = true"
                @quote-tweet-data-updated="updateQuoteTweetData"
                @removed-quote-tweet="removeQuoteTweetData"
                @update-poll="(value) => updatePoll(value, thread.guid)"
                @cleared-media="clearMediaSelectionAtIndex"
                isUsedOnAutoDMPages
                placeholder="Type the tweet that will start your campaign..."
              />
              <p class="mt-2">
                Your tweet must clearly state that your followers would receive a DM if they reply
                or retweet (as per Twitter automation rules).
              </p>
            </div>
            <div
              class="p-6 autodm-section-container border border-gray-90 dark-mode-border-0 rounded-2xl"
            >
              <div v-for="(message, idx) in messages" :key="idx">
                <!-- In case we revert back to supporting multiple messages
                  <div class="flex items-center" v-if="!isRetweetOnly">
                    <p class="text-lg font-weight-bold">What should your users reply to receive the DM? (Optional)</p>
                    <info-tooltip v-if="showTooltipInfo" content="The first reply/message will be used for retweet only" />
                  </div>

                  <div class="my-5">
                    <input
                      v-if="!isRetweetOnly"
                      type="text"
                      placeholder="I want it!"
                      v-model="message.reply"
                      class="border border-gray-90 rounded-lg text-lg placeholder-dark-mode-15 auto-dm-input px-4 h-60"
                      data-cy="reply-input"
                    />
                  </div>
                -->

                <div class="flex items-center">
                  <p class="text-lg font-weight-bold">What's the DM your users will receive?</p>
                  <info-tooltip v-if="showTooltipInfo" content="The first reply/message will be used for retweet only" />
                </div>
                <div
                  class="my-5 border border-gray-90 rounded-2xl flex items-center gap-4 autodm-section-container"
                >
                  <div class="flex flex-1 items-center">
                    <div class="auto-dm-input w-full rounded-lg border-gray-90 p-4">
                      <textarea
                        class="outline-none h-48 w-full resize-none bg-transparent text-lg leading-normal placeholder-dark-mode-15"
                        placeholder="Get your free training guide here: https://..."
                        v-model="message.content"
                        data-cy="message-input"
                      ></textarea>
                      <span class="text-lg text-dark-mode-15"
                        >If you ever want to stop receiving my DMs, reply "STOP" at any time.</span
                      >
                    </div>
                  </div>
                </div>
                <span class="text-md"
                  >Opt-out message is required to comply with Twitter automation rules.</span
                >

                <div class="mt-2" v-if="idx !== 0">
                  <tooltip content="Remove reply">
                    <base-button
                      type="secondary"
                      class="w-8 h-8 rounded-full bg-red-100 text-alert-danger outline-none focus:outline-none flex items-center justify-center app-create-delete-post"
                      @click="removeReply(idx)"
                      data-cy="remove-reply-button"
                    >
                      <inline-svg src="/img/icons/remove.svg" class="w-5" />
                    </base-button>
                  </tooltip>
                </div>
              </div>
            </div>
            <div
              class="p-6 autodm-section-container border border-gray-90 rounded-2xl"
            >
              <p class="text-lg font-weight-bold mb-6">Set your requirement(s) for getting sent a DM.</p>
              <div>
                <div
                  class="flex items-center justify-between border border-gray-90 rounded-lg text-lg px-4 h-60 flex-1 auto-dm-input mb-4"
                >
                  <select
                    class="border-0 bg-transparent text-lg text-dark-mode-15 placeholder-dark-mode-15 px-0 h-full"
                    v-model="selectedTrigger"
                    data-cy="dm-requirements-select"
                  >
                    <option value="reply-only" selected>Reply to tweet</option>
                    <option value="retweet-only">Retweet tweet</option>
                    <option value="reply-and-retweet">Reply and retweet</option>
                    <option value="reply-or-retweet">Reply or retweet</option>
                  </select>
                  <span class="text-dark-mode-15 text-black">
                    <inline-svg src="img/icons/dropdown-list-arrow.svg" />
                  </span>
                </div>
              </div>
            </div>
            <div class="p-6 autodm-section-container border border-gray-90 dark-mode-border-0 rounded-2xl" v-if="!isRetweetOnly">
              <div class="flex items-center">
                <p class="text-lg font-weight-bold">What should they reply to receive the DM? (Optional)</p>
                <info-tooltip
                  v-if="showTooltipInfo"
                  content="The first reply/message will be used for retweet only"
                />
              </div>
              <div class="my-5">
                <input
                  v-if="!isRetweetOnly"
                  type="text"
                  placeholder="If left blank, any reply will trigger the DM."
                  v-model="messages[0].reply"
                  class="border border-gray-90 rounded-lg text-lg placeholder-dark-mode-15 auto-dm-input px-4 h-60"
                  data-cy="reply-input"
                />
              </div>
            </div>
            <!-- <div
              class="p-6 border border-gray-90 autodm-section-container rounded-2xl"
            >
              <p class="text-lg font-weight-bold mb-4">Add users who received a DM to this retargeting list:</p>
              <div class="mb-2">
                <div
                  class="flex items-center justify-between border border-gray-90 rounded-lg text-lg px-4 h-60 flex-1 auto-dm-input mb-4"
                  v-if="filteredLists.length"
                >
                  <select data-cy="add-to-existing-list" class="border-0 bg-transparent text-lg text-dark-mode-15 placeholder-dark-mode-15 px-0 h-full"
                    v-model="selectedList" @change="addSelectedList">
                    <option value="" disabled selected>Add to existing list</option>
                    <option v-for="list in filteredLists" :key="list.id" :value="list">
                      {{ list.name }}
                    </option>
                  </select>
                  <span class="text-dark-mode-15 text-black">
                    <inline-svg src="img/icons/dropdown-list-arrow.svg" />
                  </span>
                </div>
                <div class="space-y-2">
                  <div class="flex-1">
                    <input
                      type="text"
                      placeholder="New list name"
                      v-model="newListName"
                      class="border border-gray-90 rounded-lg text-lg placeholder-dark-mode-15 px-4 h-60 auto-dm-input"
                      data-cy="list-name-input"
                    />
                  </div>
                  <div class="flex justify-end">
                    <tooltip content="Create a list">
                      <button
                        :disabled="!newListName"
                        @click="addList"
                        class="flex items-center justify-center w-16 h-10 rounded-full outline-none focus:outline-none app-create-add-post"
                        :class="[
                          isSubmittingList || !newListName
                            ? 'cursor-not-allowed bg-gray-60 text-white'
                            : 'cursor-pointer bg-main-color-50 text-main-color-100',
                        ]"
                        data-cy="add-button"
                      >
                        Create
                      </button>
                    </tooltip>
                  </div>
                </div>
                <div class="flex flex-wrap mt-1">
                  <div data-cy="list-item" class="max-w-full overflow-hidden pr-1" v-for="(list, idx) in selectedLists" :key="list.id">
                    <label class="bg-main-color-100">
                      <button @click="removeSelectedList(idx)" />
                      <span
                        class="flex items-center py-2 px-3 leading-none rounded-full text-white text-base font-weight-bold bg-main-color-100 cursor-pointer">{{
                        list.name }}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div> -->
            <div>
              <div class="mb-4 flex items-center justify-center gap-x-3">
                <tooltip
                  :disabled="canPostBeScheduled"
                  data-cy="compose-button-tooltip"
                  :content="createCampaignError"
                >
                  <div
                    :class="[
                      !canPostBeScheduled ? 'disabled bg-gray-60' : 'dark-mode-bg bg-main-color-50',
                    ]"
                    class="app-pick-time-button inline-flex items-center rounded-full"
                  >
                    <new-button
                      type="secondary"
                      class="rounded-l-none"
                      :disabled="!canPostBeScheduled"
                      @click="showPickATimeModal"
                      data-cy="pick-time-button"
                    >
                      Pick a time
                    </new-button>
                    <div
                      class="border-l dark-mode-border-60 flex items-center justify-center border-white"
                    >
                      <tooltip :disabled="!canPostBeScheduled" content="Post Now">
                        <new-button
                          :disabled="!canPostBeScheduled"
                          type="secondary"
                          class="rounded-r-none"
                          @click="postNow"
                          data-cy="post-now-button"
                        >
                          <inline-svg
                            src="/img/icons/arrow-right-icon.svg"
                            class="h-5 w-5 stroke-current"
                          />
                        </new-button>
                      </tooltip>
                    </div>
                  </div>
                </tooltip>
                <tooltip
                  width="180px"
                  :content="
                    canPostBeScheduled
                      ? `Add to queue <br/> ${timeToShow}`
                      : createCampaignError
                  "
                >
                  <new-button
                    data-cy="add-to-queue-button"
                    :disabled="!canPostBeScheduled"
                    @click="sendThread()"
                  >
                    Add to queue
                  </new-button>
                </tooltip>
              </div>
              <p class="mt-6 text-dark-mode-15 sm:text-lg">
                The campaign will last for 3 days.<br />
                Once launched, your DM will be sent to each user within 30 minutes of meeting your
                specified criteria.
              </p>
            </div>
          </div>
        </div>
    </div>
    <pick-a-time-modal
      v-if="isPickATimeModalVisible"
      @close="isPickATimeModalVisible = false"
      :canPostBeScheduled="canPostBeScheduled"
      @schedule-post="schedulePost"
    />

    <upgrade-to-next-plan-pop-up
      :show="showNextPlanPopUp"
      :contentForNonSubscribers="nextPlanPopUpContent"
      :content="nextPlanPopUpContent"
      @close="showNextPlanPopUp = false"
      :nextPlan="nextPlan"
    />
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import { store } from '../store';
  import NewComposerTextArea from '@/components/NewComposer/NewComposerTextArea.vue';
  import GifSelector from '@/components/Threads/GifSelector.vue';
  import lodash from 'lodash';
  import NewThreadMixin from './Mixins/NewThreadMixin.vue';
  import { Thread } from '@/models/Thread';
  import controller from '@/controller';
  import SwalModalMixin from './Mixins/SwalModalMixin.vue';
  import AutoDmCard from './AutoDMCard.vue';
  import AutoDmThreadPage from './AutoDMThreadPage.vue';
  import AutoDmCampaignPage from './AutoDMCampaignPage.vue';
  import AutoDmListPage from './AutoDMListPage.vue';
  import InfoTooltip from '@/components/InfoTooltip.vue';
  import { v4 as uuidv4 } from 'uuid';
  import moment from 'moment';
  import 'moment-timezone';
  import dao from '@/dao';
  import UploadContainerMixin from './Mixins/UploadContainerMixin.vue';
  import AutoDMMixin from './Mixins/AutoDMMixin.vue';
  import PickATimeModal from './Modals/PickATimeModal.vue';
  // import StartCampaignModal from './Modals/StartCampaignModal.vue';
  import UpgradeToNextPlanPopUp from '../components/UpgradeToNextPlanPopUp.vue';
  import UpgradeToNextPlanMixin from './Mixins/UpgradeToNextPlanMixin.vue';
  import { countTweetLength } from '@/../functions/src/util/countTweetLength';
  import { EventBus } from '@/event-bus';
  import storage from '@/storage';
  import TweetCharactersLimitMixin from './Mixins/TweetCharactersLimitMixin.vue';

  export default {
    name: 'auto-dms',
    data() {
      return {
        activeTab: 'campaigns',
        // isCreatePopUpVisible: false,
        isContactChecked: false,
        isSubmitting: false,
        selectedTrigger: 'reply-only',
        triggerOptions: ['reply'],
        requiresOnlyOneOption: false,
        isAddTo: false,
        isLoading: false,
        isSubmittingList: false,
        // campaignTitle: '',
        threadsSnapshot: null,
        showUpgradeToPremiumPopUp: false,
        newListName: '',
        selectedList: '',
        selectedLists: [],
        selectedListForRetargeting: '',
        selectedListsForRetargeting: [],
        messageForRetargeting: '',
        lists: {},
        selectedListDetails: {},
        selectedThread: null,
        isGifSelectorVisible: false,
        shortenedUrls: [],
        messages: [{ reply: '', content: '' }],
        messagedUsersData: [],
        messagedUsersDataCache: {},
        thread: this.emptyTweet(0),
        isPickATimeModalVisible: false,
        selectedCategories: [],
        uploadingMedia: [],
      };
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      ...mapState(['autoDMThreads', 'dmCampaigns', 'schedule', 'isWholeScheduleLoaded']),
      categories() {
        return this.selectedCategories.map((category) => category.id);
      },
      canViewAutoDMPage() {
        return this.isUserPlanStandardOrAbove(this.userProfile.customerStatus, false);
      },
      pageHeader() {
        switch (this.activeTab) {
          case 'create-campaign':
            return 'New Auto DM Campaign';
          default:
            return 'Auto-DMs';
        }
      },
      retargetingMessage() {
        return this.messagedUsersCount
          ? `Your DM will be sent to ${this.messagedUsersCount} user${
              this.messagedUsersCount > 1 ? 's' : ''
            } right away.`
          : 'Your DM will be sent to each user right away.';
      },
      messagedUsersCount() {
        return this.selectedListsForRetargeting.reduce(
          (acc, list) => (acc += list.messagedUsers.length),
          0,
        );
      },
      isTweetEmpty() {
        return (
          !this.thread.status && this.thread.media.length === 0 && this.thread.fileList && this.thread.fileList.length === 0
        );
      },
      isRetweetOnly() {
        return this.triggerOptions.includes('retweet') && this.triggerOptions.length === 1;
      },
      isReplyOnly() {
        return this.triggerOptions.includes('reply') && this.triggerOptions.length === 1;
      },
      hasContentForRetweet() {
        return this.messages[0].content;
      },
      hasValidReplyMessage() {
        return this.messages.some(({ reply, content }) => reply && content);
      },
      allReplyMessageAreValid() {
        return this.messages.every(({ reply, content }) => reply && content);
      },
      createCampaignError() {
        if (countTweetLength(this.thread.status) > this.tweetCharactersLimit) {
          return `Tweet exceeded character limit of ${this.tweetCharactersLimit}`;
        }

        if (this.isTweetEmpty) {
          return 'The tweet is empty';
        }

        if (this.triggerOptions.length === 0) {
          return 'Check one of the requirements for getting sent a DM';
        }

        if (this.uploadingMedia.length !== 0) {
          return 'Media is being uploaded';
        }

        if (this.isRetweetOnly) {
          if (!this.hasContentForRetweet) {
            return 'Provide a message that will be sent to your users once they retweet';
          }
        } else if (this.isReplyOnly) {
          if (this.messages.length === 1 && !this.messages[0].content) {
            return 'Check content, if reply trigger is on, make sure to have content field filled';
          }
          // } else if (this.messages.length > 0 && !this.allReplyMessageAreValid) {
          //   return 'Check reply/message, if reply trigger is on, make sure that all reply/message fields are filled';
          // }
        } else if (this.triggerOptions.length === 2 && this.requiresOnlyOneOption) {
          // if (!this.hasValidReplyMessage) {
          //   return 'Check reply/message, if reply trigger is on, make sure to have a reply field filled';
          // }
          if (!this.messages[0].content) {
            return 'Provide a message that will be sent to your users';
          }
        }
        return null;
      },
      showTooltipInfo() {
        return this.triggerOptions.length === 2 && this.requiresOnlyOneOption && this.messages.length === 2;
      },
      retargetErrorMessage() {
        if (this.selectedListsForRetargeting.length === 0) {
          return 'Choose a list to retarget to';
        } else if (!this.messageForRetargeting) {
          return 'Provide a message to send to your users';
        }
        return null;
      },
      listsForRetargeting() {
        if (this.selectedListsForRetargeting.length === 0) {
          return this.lists;
        }
        const listIds = new Set(this.selectedListsForRetargeting.map((list) => list.id));

        return Object.values(this.lists).filter((list) => !listIds.has(list.id));
      },
      filteredLists() {
        if (!this.selectedLists.length) {
          return this.userProfile.autoDMTags;
        }

        return this.userProfile.autoDMTags.filter(dmTag => !this.selectedLists.some((list) => list.id === dmTag.id));
      },
      typeOfSelectedThread() {
        if (this.selectedThread) {
          return this.selectedThread.type === 'post' ? 'thread' : 'campaign';
        }
        return null;
      },
      canAddMoreVideos() {
        return lodash.get(this.thread, 'mediaFile', []).length < 1;
      },
      getAutoDMLists() {
        if (this.selectedThread) {
          return this.userProfile.autoDMTags.filter((dmTag) =>
            this.selectedThread.autoDM.tags.includes(dmTag.id)
          );
        }
        return null;
      },
      getFormatedAutoDMThreads() {
        if (!this.canViewAutoDMPage) {
          return {
            'This week': [
              {
                id: 1,
                tweets: [
                  {
                    status: "Drop 'Great' below to take part in the giveaway!",
                  },
                ],
                autoDM: {
                  isDisabled: false,
                  messagedUsers: Array.from(Array(3040).keys()),
                },
                impressionsCountOfTheFirstTweet: 34059,
                time: moment('2023-09-25 19:40'),
                type: 'post',
              },
              {
                id: 2,
                tweets: [
                  {
                    status: 'Follow and retweet and get my ebook in your DM.',
                  },
                ],
                autoDM: {
                  isDisabled: false,
                  messagedUsers: Array.from(Array(4204).keys()),
                },
                impressionsCountOfTheFirstTweet: 43022,
                time: moment('2023-09-25 14:20'),
                type: 'post',
              },
            ],
          };
        } else {
          const threads = [];
          if (this.autoDMThreads) {
            if (this.userRanOutOfDailyDMs) {
              threads.push(...this.autoDMThreads);
            } else {
              threads.push(...this.addNextDMTimeToCampaigns(this.autoDMThreads));
            }
          }
          if (this.dmCampaigns) {
            threads.push(...this.dmCampaigns);
          }
          const now = moment();
          return threads
            .sort((t1, t2) => t2.time.diff(t1.time))
            .reduce(
              (acc, thread) => {
                const diff = now.diff(thread.time);
                if (diff < moment.duration(7, 'days')) {
                  acc['This week'].push(thread);
                } else if (diff < moment.duration(30, 'days') && diff >= moment.duration(7, 'days')) {
                  acc['Past weeks'].push(thread);
                } else if (
                  diff < moment.duration(60, 'days') &&
                  diff >= moment.duration(30, 'days')
                ) {
                  acc['Past month'].push(thread);
                } else {
                  acc['Earlier'].push(thread);
                }
                return acc;
              },
              {
                'This week': [],
                'Past weeks': [],
                'Past month': [],
                Earlier: [],
              }
            );
        }
      },
      getActiveTab() {
        return this.activeTab;
      },
      showCampaign() {
        return this.activeTab === 'campaign' && this.selectedThread !== null;
      },
      isListSelectDisabled() {
        if (this.newListName.trim().length > 0) {
          return true;
        }
        return false;
      },
      hasCampaigns() {
        return this.dmCampaigns.length || this.autoDMThreads.length;
      },
      canPostBeScheduled() {
        return this.createCampaignError === null;
      },
      timeToShow() {
        if (!this.isWholeScheduleLoaded) return;

        const time = this.schedule.getNextTimeSlot();

        if (!time) {
          const tenMinutesFromNow = moment().add(10, 'minutes');
          return `${tenMinutesFromNow.format('dddd DD')} at ${tenMinutesFromNow.format('LT')}`;
        }

        return `${time.format('dddd DD')} at ${time.format('LT')}`;
      },
    },
    mounted() {
      if (!this.autoDMThreads) {
        store.dispatch('fetchAutoDMThreads');
      }
      if (this.$route.params.post) {
        this.editCampaign(this.$route.params.post);
      }
      if (!this.dmCampaigns) {
        store.dispatch('fetchDMCampaigns');
      }

      EventBus.$on('set-uploading-media', (i) => {
        this.uploadingMedia = [...this.uploadingMedia, i];
      });

      EventBus.$on('unset-uploading-media', (i) => {
        this.uploadingMedia = [...this.uploadingMedia.filter((idx) => idx !== i)];
      });
    },
    components: {
      UpgradeToNextPlanPopUp,
      NewComposerTextArea,
      GifSelector,
      AutoDmCard,
      AutoDmThreadPage,
      AutoDmCampaignPage,
      AutoDmListPage,
      InfoTooltip,
      PickATimeModal,
    },
    watch: {
      selectedTrigger(val) {
        this.requiresOnlyOneOption = false;
        switch (val) {
          case 'reply-only':
            this.triggerOptions = ['reply'];
            break;
          case 'retweet-only':
            this.triggerOptions = ['retweet'];
            break;
          case 'reply-and-retweet':
            this.triggerOptions = ['reply', 'retweet'];
            break;
          case 'reply-or-retweet':
            this.triggerOptions = ['reply', 'retweet'];
            this.requiresOnlyOneOption = true;
            break;
        }
      },
    },
    methods: {
      showPickATimeModal() {
        this.isPickATimeModalVisible = true;
        this.$eventStore.autoDMs('Start a Campaign: Pick time');
      },
      addCategory(category) {
        if (!this.selectedCategories.find((c) => c.id === category.id)) {
          this.selectedCategories.push(category);
        }
      },
      removeCategory(category) {
        this.selectedCategories = this.selectedCategories.filter((c) => c.id !== category.id);
      },
      async createNew() {
        this.$eventStore.autoDMs('Start a Campaign: Start a Campaign');
        this.activeTab = 'create-campaign';

        // const isReplyEmpty =
        //   !this.thread.status && !this.thread.media.length && !this.thread.mediaFile.length;
        // const isReplyMessageEmpty = this.messages.every((pair) => !pair.reply && !pair.content);
        // const isTriggerOptionsEmpty = !this.triggerOptions.length;
        // const isListEmpty = !this.newListName && !this.selectedLists.length;

        // if (!isReplyEmpty || !isReplyMessageEmpty || !isTriggerOptionsEmpty || !isListEmpty) {
        //   const { isConfirmed } = await this.swalModal({
        //     title: 'Create Campaign',
        //     html: 'Do you want to continue from where you left off ?',
        //     showCancelButton: true,
        //     cancelButtonText: 'No',
        //     confirmButtonText: 'Yes',
        //   });

        // }

        // this.isCreatePopUpVisible = true;
      },
      editCampaign(post) {
        this.thread = post.tweets[0];
        this.messages = post.autoDM.messages;
        this.selectedTrigger = this.getSelectedtrigger(post.autoDM.triggerOptions, post.autoDM.requiresOnlyOneOption);
        this.activeTab = 'create-campaign';
      },
      getSelectedtrigger(triggerOptions, requiresOnlyOneOption) {
        if (triggerOptions.length === 1) {
          if (triggerOptions[0] === 'retweet') {
            return 'retweet-only';
          }
          return 'reply-only';
        }
        if (requiresOnlyOneOption) {
          return 'reply-or-retweet';
        }
        return 'reply-and-retweet';
      },
      resetMessagedUsersData() {
        this.messagedUsersData = [];
      },
      removeRetargetingTag(idx) {
        this.selectedListsForRetargeting.splice(idx, 1);
      },
      addSelectedList() {
        this.selectedLists.push(this.selectedList);
        this.selectedList = '';
      },
      removeSelectedList(idx) {
        this.selectedLists.splice(idx, 1);
      },
      removeReply(idx) {
        this.messages.splice(idx, 1);
      },
      async sendDM() {
        const getValues = () => {
          return this.selectedListsForRetargeting.filter((v) =>
            this.userProfile.autoDMTags.some((tag) => v.id === tag.id && v.name === tag.name)
          );
        };

        // We're limiting the number of users to 180 so we don't hit the rate limit
        const getMessagedUsers = (values) =>
          lodash.take(Array.from(new Set(...values.map((v) => v.messagedUsers))), 180);

        const getTags = (values) => values.map(({ id }) => id);

        const showSwalModal = async (messagedUsersCount) => {
          const { isConfirmed } = await this.swalModal({
            title: 'Create Campaign',
            html: `This will DM ${messagedUsersCount} ${
              messagedUsersCount === 1 ? 'user' : 'users'
            } right away. Are you sure?`,
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
          });
          return isConfirmed;
        };

        const sendDMs = async (messagedUsers) => {
          this.isSubmitting = true;

          await controller.twitter.dmUsers(
            this.currentUser,
            this.userProfile.uid,
            messagedUsers,
            this.messageForRetargeting
          );
        };

        const createCampaign = async (tags, messagedUsers) => {
          await dao.dmCampaign.createCampaign(this.userProfile.uid, {
            autoDM: {
              tags,
              messagedUsers,
            },
            time: new Date(),
            message: this.messageForRetargeting,
          });
        };

        const values = getValues();
        const messagedUsers = getMessagedUsers(values);
        const tags = getTags(values);

        if (!(await showSwalModal(messagedUsers.length))) return;

        try {
          await sendDMs(messagedUsers);
          await createCampaign(tags, messagedUsers);

          this.$notify({
            type: 'success',
            message: 'Campaign created successfully!',
          });
        } catch {
          this.swalModal({
            title: 'Error',
            html: `An error has occurred while trying to send the DMs.`,
            type: 'warning',
          });
        } finally {
          this.isSubmitting = false;
          this.reset();
          this.activeTab = 'campaigns';
        }
      },
      async addList() {
        try {
          this.isSubmittingList = true;
          const list = {
            name: this.newListName,
            id: uuidv4(),
          };

          const retargetingListsLimit =
            this.PLANS[this.userProfile.customerStatus].limits.autoDMRetargetingList.numberOfLists;
          if (retargetingListsLimit < lodash.get(this.userProfile, 'autoDMTags.length', 0)) {
            this.openRetargetingListsLimitNextPlanPopUp();
            return;
          }

          this.selectedLists.push(list);
          await dao.userProfile.addAutoDMTag(this.userProfile.uid, list);
        } catch {
          this.swalModal({
            title: 'Error',
            html: 'An error has occured while trying to create an AutoDM list.',
            type: 'warning',
          });
        } finally {
          this.isSubmittingList = false;
          this.newListName = '';
        }
      },
      addListForRetargeting() {
        this.selectedListsForRetargeting.push(this.selectedListForRetargeting);
        this.selectedListForRetargeting = '';
      },
      async addGifToTweet(gifURL) {
        if (!this.canAddMoreVideos) {
          return;
        }

        const image = await controller.proxyFile(this.currentUser, gifURL);
        this.thread = {
          ...this.thread,
          mediaFile: [...this.thread.mediaFile, { url: gifURL, type: 'gif' }],
        };

        const f = new File([image], 'tenor.gif', { type: 'image/gif' });

        EventBus.$emit('set-uploading-media', 0);
        const { file, thumbnail } = await storage.uploadNewMedia(f);
        this.thread = {
          ...this.thread,
          media: [{
            name: file.metadata.name,
            type: file.metadata.contentType,
            size: file.metadata.size,
            ...(thumbnail ? { thumbnail } : {}),
          }],
          mediaFile: [...this.thread.mediaFile, { url: gifURL, type: 'gif' }],
        };
        EventBus.$emit('unset-uploading-media', 0);
      },
      loadListsWithMessagedUsers() {
        if (!this.canViewAutoDMPage) {
          this.lists = {
            'random-id-1': {
              id: 'random-id-1',
              name: 'List for retargetting',
              messagedUsers: Array.from(Array(340).keys()),
            },
            'random-id-2': {
              id: 'random-id-2',
              name: 'My list',
              messagedUsers: Array.from(Array(412).keys()),
            },
          };
        } else {
          if (Object.keys(this.lists).length === 0) {
            this.userProfile.autoDMTags.forEach((tag) => {
              const messagedUsers = this.autoDMThreads.reduce((acc, thread) => {
                if (thread.autoDM.tags.includes(tag.id) && thread.autoDM.messagedUsers) {
                  acc.push(...thread.autoDM.messagedUsers);
                }
                return acc;
              }, []);
              if (messagedUsers.length > 0) {
                this.lists[tag.id] = {
                  ...tag,
                  messagedUsers: [...new Set(messagedUsers)],
                };
              }
            });
          }
        }
      },
      openListTab() {
        this.loadListsWithMessagedUsers();
        this.activeTab = 'lists';
      },
      async openList(list) {
        this.resetMessagedUsersData();
        this.selectedListDetails = {
          ...list,
        };
        this.activeTab = 'list';
        this.messagedUsersData = await this.loadUsersDetails(list.messagedUsers);
      },
      async loadUsersDetails(messagedUsers, status = 'Sent') {
        try {
          const [cachedUsers, remaining] = messagedUsers.reduce(
            ([cachedUsers, remaining], userId) => {
              const data = this.messagedUsersDataCache[userId];
              if (data) {
                cachedUsers.push(data);
              } else {
                remaining.push(userId);
              }
              return [cachedUsers, remaining];
            },
            [[], []]
          );
          if (remaining.length === 0) {
            return cachedUsers;
          }

          const retargetingListSizeLimit =
            this.PLANS[this.userProfile.customerStatus].limits.autoDMRetargetingList.listSize;
          const numberOfUsersToTake =
            cachedUsers.length > retargetingListSizeLimit
              ? 0
              : retargetingListSizeLimit - cachedUsers.length;

          const promises = lodash
            .chunk(lodash.take(remaining, numberOfUsersToTake), 100)
            .map((userIds) =>
              controller.twitter.getUsers(this.currentUser, this.userProfile.uid, userIds)
            );
          const newUsersData = (await Promise.all(promises)).flat();
          newUsersData.forEach((user) => {
            user.status = status;
            this.messagedUsersDataCache[user.id] = user;
          });
          return [...cachedUsers, ...newUsersData];
        } catch {
          this.swalModal({
            title: 'Sorry',
            text: `An error has occurred while trying to fetch the users.`,
            type: 'warning',
          });
          return [];
        }
      },
      getNextTimeSlot() {
        const tenMinutesFromNow = moment().add(10, 'minutes');
        let slotDate = tenMinutesFromNow.format('YYYY-MM-DD');
        let slotTime = tenMinutesFromNow.format('HH:mm');

        const nextTimeSlot = this.schedule.getNextTimeSlot();

        if (nextTimeSlot) {
          slotDate = nextTimeSlot.format('YYYY-MM-DD');
          slotTime = nextTimeSlot.format('HH:mm');
        }
        return `${slotDate}T${slotTime}`;
      },
      async sendThread(shouldPostNow = false, time = null) {
        this.$eventStore.autoDMs('Start a Campaign: Add to Queue');
        this.isSubmitting = true;

        try {
          const { data: doesTextHasOptIn } = await controller.checkIfTextHasOptIn(
            this.currentUser,
            this.userProfile.uid,
            this.thread.status,
          );

          if (!doesTextHasOptIn) {
            this.isSubmitting = false;
            this.swalModal({
              title: 'Sorry',
              text: 'Your tweet must have a clear message that your followers are opting-in for your DMs. Please add it and try again.',
              type: 'warning',
              preConfirm: () => {
                this.$eventStore.autoDMs('Start a Campaign: Ok on popup that is triggered if there is not opt-in message in tweet itsef');
              }
            });
            return;
          }
        } catch (error) {
          console.error(error);
        }

        const threadTime = time ? time : this.getNextTimeSlot();

        const tweets = [this.thread];
        const lists = [];

        for (const list of this.selectedLists) {
          const found = this.userProfile.autoDMTags.find(
            (dmTag) => dmTag.id === list.id && dmTag.name === list.name
          );
          found && lists.push(list.id);
        }

        const linkRegex = /(https?:\/\/[^\s]+)/g;
        const links = this.messages.reduce((links, message) => {
          const matches = message.content.match(linkRegex);
          if (matches) {
            links.push(...matches);
          }
          return links;
        }, []);

        const slugs = await (async () => {
          try {
            if (links.length < 0) {
              return [];
            }
            const body = {
              source: 'auto-dm',
              links,
            };
            const { data } = await controller.links.shortenUrls(
              this.currentUser,
              this.userProfile.uid,
              body
            );
            return data.map((link) => link.slug);
          } catch {
            return [];
          }
        })();

        const isReplyOrRetweet = this.triggerOptions.length === 2 && this.requiresOnlyOneOption;
        const messages = !(isReplyOrRetweet && this.messages.length > 1)
          ? this.messages
          : this.messages.map(({ reply, content }) => {
              // if (i === 0) {
              //   return { reply: '', content };
              // }
              // return { reply, content };
              // Note: uncomment the above code and remove the below code in case we revert back to supporting multiple messages
              return { reply: this.isRetweetOnly ? '' : reply, content };
            });

        const isTrialAllowed = false;
        const autoDM = {
          tags: lists,
          messages,
          triggerOptions: this.triggerOptions,
          ...(slugs.length > 0 ? { shortenedUrlsSlugs: slugs } : {}),
          isFree: this.isUserPlanPremiumOrAbove(this.userProfile.customerStatus, isTrialAllowed),
          enabled: true,
          requiresOnlyOneOption: this.requiresOnlyOneOption,
        };

        const thread = Thread.newThread({
          time: shouldPostNow ? new Date() : moment.tz(threadTime, this.timezone).toDate(),
          tweets,
          user: this.userProfile.uid,
          postNow: shouldPostNow,
          categories: this.categories,
          autoDM: autoDM,
        });

        try {
          await thread.saveToFirestore(
            this.currentUser,
            this.userProfile.uid,
            this.timezone,
            this.$eventStore
          );
          this.$notify({ type: 'success', message: 'Campaign on its way!' });
        } catch (error) {
          this.swalModal({
            title: 'Sorry',
            text: `An error has occurred while posting the Auto-DM thread.`,
            type: 'warning',
          });
        } finally {
          this.activeTab = 'campaigns';
          this.isSubmitting = false;
          this.reset();
        }
      },
      schedulePost(time) {
        this.isPickATimeModalVisible = false;
        this.sendThread(false, time);
      },
      reset() {
        this.triggerOptions = [];
        this.selectedLists = [];
        this.requiresOnlyOneOption = false;
        this.thread = this.emptyTweet(0);
        this.messages = [{ reply: '', content: '' }];
        this.selectedListForRetargeting = [];
        this.selectedListForRetargeting = '';
        this.messageForRetargeting = '';
      },
      newCampaign() {
        this.activeTab = 'create-campaign';
        // this.isCreatePopUpVisible = false;
      },
      retarget() {
        this.loadListsWithMessagedUsers();
        this.activeTab = 'retargeting';
        // this.isCreatePopUpVisible = false;
      },
      async fetchShortenedUrlsData(thread) {
        const slugs = thread.autoDM.shortenedUrlsSlugs;
        const { data } = await controller.links.getShortenedUrlsBySlugs(this.currentUser, this.userProfile.uid, slugs);
        return data;
      },
      async openThread(thread) {
        this.activeTab = 'campaign';
        this.resetMessagedUsersData();
        this.selectedThread = thread;
        this.shortenedUrls = await this.fetchShortenedUrlsData(thread);
        if (thread.autoDM.messagedUsers) {
          this.messagedUsersData = await this.loadUsersDetails(thread.autoDM.messagedUsers);
        }
        if (thread.autoDM.failedDMs) {
          this.messagedUsersData.push(
            ...(await this.loadUsersDetails(thread.autoDM.failedDMs, 'In queue'))
          );
        }
      },
      updatePoll(poll) {
        this.thread = { ...this.thread, poll };
      },
      updateStatus(value) {
        this.thread = {
          ...this.thread,
          status: value,
        };
      },
      removeQuoteTweetData() {
        this.thread = { ...this.thread, quoteTweetData: null };
      },
      updateQuoteTweetData(quoteURL) {
        this.thread = { ...this.thread, quoteTweetData: quoteURL };
      },
      clearMediaSelectionAtIndex(_, i) {
        if (this.threadToEdit) {
          const mediaName = this.thread.mediaFile[i].name;
          // TODO: Update after fixing storage.deleteOldMedia
          // const mediaThumbnail = this.thread.mediaFile[i].thumbnail;
          // this.setMediaToDelete(mediaName, mediaThumbnail);

          const newMediaFile = this.thread.mediaFile.filter((media) => media.name !== mediaName);
          const newFileList = this.thread.fileList.filter((media) => media.name !== mediaName);
          const newMedia = this.thread.media.filter((media) => media.name !== mediaName);

          this.thread = {
            ...this.thread ,
            mediaFile: newMediaFile,
            fileList: newFileList,
            media: newMedia,
          };
        } else {
          this.thread = {
            ...this.thread,
            mediaFile: this.thread.mediaFile.filter((_, index) => index !== i),
            fileList: this.thread.fileList.filter((_, index) => index !== i),
          };
        }
      },
      changeTab(tab) {
        this.activeTab = tab;
      },
      postNow() {
        this.$eventStore.autoDMs('Start a Campaign: Post now');
        this.swalModal({
          title: `Post Now`,
          html: 'Are you sure you want to post to Twitter now?',
          type: 'question',
          showCancelButton: true,
          confirmButtonText: 'Yes, post now',
          preConfirm: () => {
            this.sendThread(true);
          },
        });
      },
    },
    mixins: [
      NewThreadMixin,
      SwalModalMixin,
      UploadContainerMixin,
      AutoDMMixin,
      UpgradeToNextPlanMixin,
      TweetCharactersLimitMixin,
    ],
  };
</script>

<style>
  .sample-overlay {
    background: url('/img/SAMPLE.svg');
  }
</style>
