<template>
  <base-modal @close="$emit('close')">
    <div class="app-create-modal relative z-10 max-w-md rounded-3xl bg-white p-8">
      <div class="grid gap-6">
        <div class="flex items-center justify-between gap-4">
          <div class="flex items-center gap-4">
            <div class="flex h-12 w-12 items-center justify-center rounded-full bg-main-color-100">
              <inline-svg src="/img/icons/calendar-icon.svg" class="fill-white" />
            </div>
            <span class="text-2xl font-bold">Pick a time</span>
          </div>
          <new-button icon type="transparent" @click="$emit('close')">
            <inline-svg src="/img/icons/close.svg" />
          </new-button>
        </div>
        <div
          v-if="getTimeError(fullTime)"
          class="pb-4 text-badge-error"
          v-html="getTimeError(fullTime)"
        ></div>
        <div>
          <span class="mb-2 block text-base font-medium text-primary">Time Slots</span>

          <div class="flex flex-wrap">
            <span v-if="timeSlots.length === 0">
              No time slots available for the selected date.
            </span>
            <div class="flex flex-wrap gap-2">
              <new-base-radio
                v-for="(slot, index) in timeSlots"
                :key="index"
                :value="getSlotTime(slot.time, 24)"
                v-model="time"
                size="md"
                type="dark"
                data-cy="pick-time-slot-button"
              >
                {{ getSlotTime(slot.time) }}
              </new-base-radio>
            </div>
          </div>
        </div>
        <hr />
        <div class="flex flex-wrap items-start gap-x-6 gap-y-4">
          <div class="flex-grow">
            <label for="date" class="mb-2 block text-lg font-medium text-primary">Date</label>
            <div
              class="border app-modal-input flex cursor-pointer items-center gap-3 rounded-lg border-gray-90 bg-white px-4 py-3"
            >
              <inline-svg src="/img/icons/date-icon.svg" class="h-6 w-6 fill-current" />
              <input
                type="date"
                class="flex-1 cursor-pointer text-lg"
                v-model="date"
                :min="minDate"
                data-cy="pick-time-date-input"
              />
            </div>
          </div>
          <div class="flex-grow">
            <label for="time" class="mb-2 block text-lg font-medium text-primary">Time</label>
            <div
              class="border app-modal-input flex cursor-pointer items-center gap-3 rounded-lg border-gray-90 bg-white px-4 py-3"
            >
              <inline-svg src="/img/icons/clock-icon.svg" class="h-6 w-6 fill-current" />
              <input
                type="time"
                class="flex-1 cursor-pointer text-lg"
                v-model="time"
                aria-label="Select time"
                data-cy="pick-time-time-input"
              />
            </div>
          </div>
        </div>
        <div class="flex items-center justify-end">
          <new-button data-cy=pick-time-schedule-button :disabled="postHasErrors" @click="$emit('schedule-post', fullTime)">
            Schedule
          </new-button>
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script>
  import moment from 'moment';
  import 'moment-timezone';
  import lodash from 'lodash';
  import { mapGetters, mapState } from 'vuex';

  export default {
    name: 'pick-a-time-modal',
    props: {
      canPostBeScheduled: {
        type: Boolean,
        default: false,
      },
    },
    mounted() {
      this.setTimeToNextTimeSlot();
    },
    data() {
      return {
        date: null,
        time: null,
      };
    },
    computed: {
      ...mapGetters({
        userProfile: 'getUserProfile',
      }),
      ...mapState(['schedule']),
      timezone() {
        return lodash.get(this, 'userProfile.timezone', moment.tz.guess());
      },
      minDate() {
        return moment().format('YYYY-MM-DD');
      },
      timeSlots() {
        if (!this.schedule) return [];
        const midnight = moment.tz(this.date, this.timezone).startOf('day').toISOString();
        const slots = this.schedule.getThreadsByDate()[midnight];
        const emptySlots = slots ? slots.filter((slot) => slot.slotType === 'empty') : [];
        return emptySlots;
      },
      fullTime() {
        if (!this.date || !this.time) return null;
        return `${this.date}T${this.time}`;
      },
      postHasErrors() {
        return this.getTimeError(this.fullTime) || !this.canPostBeScheduled;
      },
    },
    methods: {
      getTimeStatus(time) {
        const getDiffBetweenLocalTimezoneAndOtherTimezone = () => {
          const localOffset = moment().utcOffset();
          const otherOffset = moment.tz(this.timezone).utcOffset();
          return localOffset - otherOffset;
        };
        const momentTime = moment(time);

        if (!momentTime._isValid) {
          return 'invalid';
        }

        const that = this;
        const getCurrentSlotLength = () => {
          if (that.schedule) {
            const selectedDate = Object.entries(that.schedule.getThreadsByDate()).find((elem) => {
              return moment(elem[0]).isSame(momentTime, 'day');
            });
            if (selectedDate) {
              const selectedTimeSlot = selectedDate[1].filter((elem) => {
                return (
                  !['empty', 'recurrent', 'evergreen'].includes(elem.slotType) &&
                  moment(elem.time).isSame(momentTime, 'hour') &&
                  moment(elem.time).isSame(momentTime, 'minute')
                );
              });
              return selectedTimeSlot.length;
            }
          }
        };

        const minutesDifference = getDiffBetweenLocalTimezoneAndOtherTimezone();
        const clonedTime = lodash.clone(momentTime);
        clonedTime.add(minutesDifference, 'minute');

        const now = moment().add(minutesDifference, 'minutes').startOf('minute');
        const diff = clonedTime.diff(now, 'minutes');
        if (
          diff + minutesDifference <= 0 ||
          (diff + minutesDifference === 0 && clonedTime.minutes() === now.minutes())
        ) {
          return 'past';
        }

        const oneDayFromNow = moment.tz(this.timezone).add(1, 'day').toDate();
        if (
          this.userProfile.customerStatus === 'none' &&
          clonedTime.isAfter(oneDayFromNow, 'day')
        ) {
          return 'more than two days';
        }

        if (clonedTime > moment().add(1, 'year')) {
          return 'more than one year';
        }

        const slotLength = getCurrentSlotLength();
        if (slotLength === 2) return 'slot full';
        return 'valid';
      },
      getTimeError(time) {
        switch (this.getTimeStatus(time)) {
          case 'invalid':
            return 'Invalid date.';
          case 'past':
            return 'The selected date is in the past.';
          case 'more than one year':
            return 'Scheduling is limited to 1 year from now.';
          case 'slot full':
            return 'You have already scheduled 2 tweets for the selected date.';
          case 'more than two days':
            return 'As a free plan user you are only allowed to schedule up to 2 days.';
          default:
            return '';
        }
      },
      getSlotTime(slotTime, hourType) {
        const timeFormat = hourType === 24 ? 'HH:mm' : 'hh:mm A';
        return slotTime.format(timeFormat);
      },
      setTimeToNextTimeSlot() {
        const nextTimeSlot = this.schedule.getNextTimeSlot();

        if (nextTimeSlot) {
          this.date = nextTimeSlot.format('YYYY-MM-DD');
          this.time = nextTimeSlot.format('HH:mm');
        } else {
          const tenMinutesFromNow = moment().add(10, 'minutes');
          this.date = tenMinutesFromNow.format('YYYY-MM-DD');
          this.time = tenMinutesFromNow.format('HH:mm');
        }
      },
    },
  };
</script>
