<template>
  <div>
    <div class="grid gap-4">
      <div class="flex items-center justify-between">
        <h3 class="flex items-center gap-2 text-2xl font-bold">
          Campaign of
        </h3>
        <div data-cy="campaign-status" class="flex items-center gap-2">
          <button v-if="isCampaignFinished" class="button small-button text-success-100">
            Finished
          </button>
          <button
            v-else-if="campaign.autoDM.isDisabled"
            class="button small-button text-danger-100"
          >
            Disabled
          </button>
          <button v-else class="button small-button text-success-100">Sent</button>
          <!-- <span class="cursor-pointer">
            <inline-svg src="/img/icons/options-dots.svg" />
          </span> -->
        </div>
      </div>

      <p class="text-lg">
        {{ campaign.time.format('dddd, MMMM Do h:mm a') }}
        <span data-cy="card-recipients-count" class="text-dark-mode-15"
          >to
          {{recipientsCount}}
          recipients</span
        >
      </p>
      <p data-cy="dm-status-message" v-if="type === 'thread'" class="text-md">
        {{ statusMessage }}
      </p>

      <p data-cy="card-impressions-count" v-if="type==='thread'" class="text-dark-mode-15 text-lg flex items-center gap-1">
        {{ impressionsCount }}
        impressions
      </p>

      <p v-if="timeLeftToDM !== null" class="text-md">
        Your next DM will be sent in
        <span class="text-dark-mode-15">{{ timeLeftToDM }} minute{{ timeLeftToDM === 1 ? '' : 's' }}</span>
      </p>
    </div>

    <a href="javascript:;" class="absolute inset-0 z-10" @click="openThread()" />
  </div>
</template>

<script>
  import moment from 'moment';

  export default {
    computed: {
      statusMessage() {
        const tweetMessage = this.campaign.tweets[0].status;
        return tweetMessage.length > 55 ? `${tweetMessage.slice(0, 55)}...` : tweetMessage;
      },
      impressionsCount() {
        return this.campaign.impressionsCountOfTheFirstTweet
          ? this.campaign.impressionsCountOfTheFirstTweet.toLocaleString()
          : 0;
      },
      recipientsCount() {
        return (this.campaign.autoDM.messagedUsers && this.campaign.autoDM.messagedUsers.length) || 0;
      },
      type() {
        return this.campaign.type === 'post' ? 'thread' : 'campaign';
      },
      timeLeftToDM() {
        return this.campaign.nextDMTime
          ? moment(this.campaign.nextDMTime)
              .startOf('minute')
              .diff(this.now.startOf('minute'), 'minutes')
          : null;
      },
      isCampaignFinished() {
        return moment().subtract(3, 'days').isAfter(this.campaign.time.toDate());
      },
    },
    mounted() {
      // This hack allows us to make the time reactive
      const interval = setInterval(() => {
        this.now = moment();
      }, 1000 * 60);
      this.$once('hook:beforeDestroy', () => {
        clearInterval(interval);
      });
    },
    data() {
      return {
        now: moment(),
      };
    },
    props: {
      campaign: {
        type: Object,
        required: true,
      },
    },
    methods: {
      openThread() {
        this.$emit('openThread', this.campaign);
      },
    },
  };
</script>
