<template>
  <div class="grid gap-8">
    <div class="flex items-center gap-5">
      <span data-cy="back-button" @click="goPreviousPage" class="cursor-pointer">
        <inline-svg src="img/icons/back-arrow-icon.svg"/>
      </span>
      <h3 data-cy="list-name" class="text-32 font-bold">{{ listDetails.name }}</h3>
    </div>
    <!-- <div -->
    <!--   class="dark-mode:bg-dark-mode-70 bg-white border border-gray-90 dark-mode:border-0 rounded-2xl p-6 grid gap-6" -->
    <!-- > -->
    <!--   <div> -->
    <!--     <h4 class="text-xl font-bold">List Performance</h4> -->
    <!--   </div> -->
    <!--   <div> -->
    <!--     <div class="flex items-center justify-between mb-4"> -->
    <!--       <span class="text-lg font-medium">Open rate</span> -->
    <!--       <span class="textlg">148 / 2,413</span> -->
    <!--     </div> -->
    <!--     <div class="relative dark-mode:bg-dark-mode-60 bg-gray-90 h-2 rounded-xl"> -->
    <!--       <div -->
    <!--         class="absolute top-0 left-0 h-full bg-success-100 rounded-xl" -->
    <!--         style="width: 20%" -->
    <!--       ></div> -->
    <!--     </div> -->
    <!--   </div> -->
    <!--   <div> -->
    <!--     <div class="flex items-center justify-between mb-4"> -->
    <!--       <span class="text-lg font-medium">Click rate</span> -->
    <!--       <span class="textlg">148 / 2,413</span> -->
    <!--     </div> -->
    <!--     <div class="relative dark-mode:bg-dark-mode-60 bg-gray-90 h-2 rounded-xl"> -->
    <!--       <div -->
    <!--         class="absolute top-0 left-0 h-full bg-success-100 rounded-xl" -->
    <!--         style="width: 20%" -->
    <!--       ></div> -->
    <!--     </div> -->
    <!--   </div> -->
    <!--   <div class="grid grid-cols-2"> -->
    <!--     <div> -->
    <!--       <span class="block mb-6 font-bold text-xl leading-none">List avg</span> -->
    <!--       <div class="flex items-center gap-6"> -->
    <!--         <div> -->
    <!--           <span class="text-xl font-medium block">6.1%</span> -->
    <!--           <span class="text-base text-dark-mode-15 font-medium">Engaged</span> -->
    <!--         </div> -->
    <!--         <div> -->
    <!--           <span class="text-xl font-medium block">56.1%</span> -->
    <!--           <span class="text-base text-dark-mode-15 font-medium">Opens</span> -->
    <!--         </div> -->
    <!--       </div> -->
    <!--     </div> -->
    <!--     <div> -->
    <!--       <span class="block mb-6 font-bold text-xl leading-none">Industry avg</span> -->
    <!--       <div class="flex items-center gap-6"> -->
    <!--         <div> -->
    <!--           <span class="text-xl font-medium block">6.1%</span> -->
    <!--           <span class="text-base text-dark-mode-15 font-medium">Engaged</span> -->
    <!--         </div> -->
    <!--         <div> -->
    <!--           <span class="text-xl font-medium block">56.1%</span> -->
    <!--           <span class="text-base text-dark-mode-15 font-medium">Opens</span> -->
    <!--         </div> -->
    <!--       </div> -->
    <!--     </div> -->
    <!--   </div> -->
    <!-- </div> -->
    <div class="rounded-2xl bg-white dark-mode-bg border border-gray-90 dark-mode-border-0">
      <div
        class="flex items-center justify-between p-6 border-b border-gray-90 dark-mode-border-0"
      >
        <span data-cy="list-number-users" class="text-lg">{{ listDetails.messagedUsers.length }} contacts</span>
        <!-- <div class="flex items-center gap-5"> -->
        <!--   <span -->
        <!--     @click="isAddTo = true" -->
        <!--     class="flex items-center gap-2 text-base cursor-pointer" -->
        <!--     :class="[ -->
        <!--       isContactChecked -->
        <!--         ? 'text-main-color-30' -->
        <!--         : 'text-dark-mode-15 pointer-events-none', -->
        <!--     ]" -->
        <!--   > -->
        <!--     <svg -->
        <!--       width="16" -->
        <!--       height="17" -->
        <!--       viewBox="0 0 16 17" -->
        <!--       fill="none" -->
        <!--       xmlns="http://www.w3.org/2000/svg" -->
        <!--       class="stroke-current" -->
        <!--     > -->
        <!--       <path -->
        <!--         d="M8.00016 15.1668C11.6821 15.1668 14.6668 12.1821 14.6668 8.50016C14.6668 4.81826 11.6821 1.8335 8.00016 1.8335C4.31826 1.8335 1.3335 4.81826 1.3335 8.50016C1.3335 12.1821 4.31826 15.1668 8.00016 15.1668Z" -->
        <!--         stroke-width="1.5" -->
        <!--         stroke-linecap="round" -->
        <!--         stroke-linejoin="round" -->
        <!--       /> -->
        <!--       <path -->
        <!--         d="M8 10.8335V6.8335" -->
        <!--         stroke-width="1.5" -->
        <!--         stroke-linecap="round" -->
        <!--         stroke-linejoin="round" -->
        <!--       /> -->
        <!--       <path -->
        <!--         d="M6 8.1665L8 6.1665L10 8.1665" -->
        <!--         stroke-width="1.5" -->
        <!--         stroke-linecap="round" -->
        <!--         stroke-linejoin="round" -->
        <!--       /> -->
        <!--     </svg> -->
        <!--     Add to -->
        <!--   </span> -->
        <!--   <span -->
        <!--     class="flex items-center gap-2 text-base cursor-pointer" -->
        <!--     :class="[ -->
        <!--       isContactChecked -->
        <!--         ? 'text-danger-100' -->
        <!--         : 'text-dark-mode-15 pointer-events-none', -->
        <!--     ]" -->
        <!--   > -->
        <!--     <svg -->
        <!--       width="16" -->
        <!--       height="17" -->
        <!--       viewBox="0 0 16 17" -->
        <!--       fill="none" -->
        <!--       xmlns="http://www.w3.org/2000/svg" -->
        <!--       class="stroke-current" -->
        <!--     > -->
        <!--       <path -->
        <!--         d="M8.00016 15.1668C11.6668 15.1668 14.6668 12.1668 14.6668 8.50016C14.6668 4.8335 11.6668 1.8335 8.00016 1.8335C4.3335 1.8335 1.3335 4.8335 1.3335 8.50016C1.3335 12.1668 4.3335 15.1668 8.00016 15.1668Z" -->
        <!--         stroke-width="1.5" -->
        <!--         stroke-linecap="round" -->
        <!--         stroke-linejoin="round" -->
        <!--       /> -->
        <!--       <path -->
        <!--         d="M6.11328 10.3866L9.88661 6.61328" -->
        <!--         stroke-width="1.5" -->
        <!--         stroke-linecap="round" -->
        <!--         stroke-linejoin="round" -->
        <!--       /> -->
        <!--       <path -->
        <!--         d="M9.88661 10.3866L6.11328 6.61328" -->
        <!--         stroke-width="1.5" -->
        <!--         stroke-linecap="round" -->
        <!--         stroke-linejoin="round" -->
        <!--       /> -->
        <!--     </svg> -->
        <!--     Remove -->
        <!--   </span> -->
        <!-- </div> -->
      </div>
      <div class="bg-white dark-mode-bg-90 px-6 py-4 flex items-center gap-3 border-b border-gray-90 dark-mode-border-0">
        <div
          class="flex items-center bg-white dark-mode-bg border border-gray-90 dark-mode-border-0 h-10 rounded-full gap-2 px-4 flex-1"
        >
          <inline-svg src="/img/icons/search-new-ui.svg"></inline-svg>
          <input
            type="text"
            v-model="searchText"
            class="bg-transparent border-0 text-sm text-dark-mode-15 font-medium px-0"
            placeholder="Search"
            data-cy="search-input"
          />
        </div>
        <!-- <div -->
        <!--   class="flex items-center dark-mode:bg-dark-mode-70 bg-white border border-gray-90 dark-mode:border-0 h-10 rounded-full gap-2 px-4 cursor-pointer" -->
        <!-- > -->
        <!--   <span class="text-sm text-dark-mode-15 font-medium">Open rate</span> -->
        <!--   <svg -->
        <!--     width="11" -->
        <!--     height="8" -->
        <!--     viewBox="0 0 11 8" -->
        <!--     fill="none" -->
        <!--     xmlns="http://www.w3.org/2000/svg" -->
        <!--   > -->
        <!--     <path -->
        <!--       d="M1 1.75L5.5 6.25L10 1.75" -->
        <!--       stroke="#8E96AE" -->
        <!--       stroke-width="2" -->
        <!--       stroke-linecap="round" -->
        <!--     /> -->
        <!--   </svg> -->
        <!-- </div> -->
        <!-- <div -->
        <!--   class="flex items-center dark-mode:bg-dark-mode-70 bg-white border border-gray-90 dark-mode:border-0 h-10 rounded-full gap-2 px-4 cursor-pointer" -->
        <!-- > -->
        <!--   <span class="text-sm text-dark-mode-15 font-medium">Click rate</span> -->
        <!--   <svg -->
        <!--     width="11" -->
        <!--     height="8" -->
        <!--     viewBox="0 0 11 8" -->
        <!--     fill="none" -->
        <!--     xmlns="http://www.w3.org/2000/svg" -->
        <!--   > -->
        <!--     <path -->
        <!--       d="M1 1.75L5.5 6.25L10 1.75" -->
        <!--       stroke="#8E96AE" -->
        <!--       stroke-width="2" -->
        <!--       stroke-linecap="round" -->
        <!--     /> -->
        <!--   </svg> -->
        <!-- </div> -->
      </div>
      <div
        class="pb-6 border-gray-90 dark-mode-border-60"
      >
        <table class="w-full">
          <thead>
            <tr class="border-gray-90 dark-mode-border-60 border-b">
              <th class="text-base font-medium bg-transparent text-left font-main-font px-6 py-4">
                <div class="flex items-center">
                  <!-- <div class="checkbox"> -->
                  <!--   <input id="0" type="checkbox" class="hidden" /> -->
                  <!--   <label for="0"></label> -->
                  <!-- </div> -->
                  <span>Name</span>
                </div>
              </th>
              <!-- <th -->
              <!--   class="text-base font-medium bg-transparent text-left font-main-font px-6 py-4" -->
              <!-- > -->
              <!--   Open rate -->
              <!-- </th> -->
              <!-- <th -->
              <!--   class="text-base font-medium bg-transparent text-left font-main-font px-6 py-4" -->
              <!-- > -->
              <!--   Click rate -->
              <!-- </th> -->
            </tr>
          </thead>
          <tbody v-if="searchText.length === 0">
            <tr data-cy="user-list-item" v-for="user in messagedUsersData" :key="user.id">
              <td class="px-6 pt-4 pb-0 border-0">
                <div class="flex items-center">
                  <!-- <div class="checkbox"> -->
                  <!--   <input -->
                  <!--     id="1" -->
                  <!--     type="checkbox" -->
                  <!--     class="hidden" -->
                  <!--     v-model="isContactChecked" -->
                  <!--   /> -->
                  <!--   <label for="1"></label> -->
                  <!-- </div> -->
                  <div class="flex items-center gap-2">
                    <img
                      :src="user.profile_image_url"
                      :alt="user.name"
                      class="w-6 h-6 rounded-full object-cover"
                    />
                    <a class="text-base" :href="`https://twitter.com/${user.username}`" target="_blank">{{
                      user.name
                    }}</a>
                  </div>
                </div>
              </td>
              <!-- <td class="px-6 pt-4 pb-0 border-0"> -->
              <!--   <span class="text-base">33% (3)</span> -->
              <!-- </td> -->
              <!-- <td class="px-6 pt-4 pb-0 border-0"> -->
              <!--   <span class="text-base">0% (3)</span> -->
              <!-- </td> -->
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="user in filteredUsers" :key="user.id">
              <td class="px-6 pt-4 pb-0 border-0">
                <div class="flex items-center">
                  <!-- <div class="checkbox"> -->
                  <!--   <input -->
                  <!--     id="1" -->
                  <!--     type="checkbox" -->
                  <!--     class="hidden" -->
                  <!--     v-model="isContactChecked" -->
                  <!--   /> -->
                  <!--   <label for="1"></label> -->
                  <!-- </div> -->
                  <div class="flex items-center gap-2">
                    <img
                      :src="user.profile_image_url"
                      :alt="user.name"
                      class="h-6 w-6 rounded-full object-cover"
                    />
                    <a class="text-base" :href="`https://twitter.com/${user.username}`">{{
                      user.name
                    }}</a>
                  </div>
                </div>
              </td>
              <!-- <td class="px-6 pt-4 pb-0 border-0"> -->
              <!--   <span class="text-base">33% (3)</span> -->
              <!-- </td> -->
              <!-- <td class="px-6 pt-4 pb-0 border-0"> -->
              <!--   <span class="text-base">0% (3)</span> -->
              <!-- </td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
     return {
        searchText: '',
      }
    },
    props: {
      listDetails: {
        type: Object,
        required: true,
      },
      messagedUsersData: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      filteredUsers() {
        const searchText = this.searchText.toLowerCase();
        return this.messagedUsersData.filter(
          (user) =>
            user.username.toLowerCase().includes(searchText) ||
            user.name.toLowerCase().includes(searchText)
        );
      },
    },
    methods: {
      goPreviousPage(){
        this.$emit('clear-messaged-users-data');
        this.$emit('go-previous-page', 'lists');
      }
    }
  }
</script>
