<template>
  <modal :show="show" @close="close">
    <div data-cy="edit-lists-modal-header" slot="header" class="modal-title">Edit Lists</div>

    <div>
      <div role="form" class="needs-validation">
        <div class="flex flex-col gap-y-8">
          <div class="flex flex-wrap gap-2">
            <div
              data-cy="list-item"
              class="max-w-full overflow-hidden pr-1"
              v-for="list in userProfile.autoDMTags"
              :key="list.id"
            >
              <new-base-checkbox
                :label="list.name"
                :inputValue="list.id"
                v-model="lists"
                type="tag"
              />
            </div>
          </div>
          <a data-cy="create-new-list-link" v-if="!isAddNewListEnabled" href="#" @click="isAddNewListEnabled = true"
            >Create New List</a
          >
          <div v-if="isAddNewListEnabled">
            <div class="flex gap-x-3">
              <input
                type="text"
                placeholder="New list name"
                v-model="newListName"
                class="border auto-dm-input h-60 rounded-lg border-gray-90 px-4 text-lg placeholder-dark-mode-15"
                data-cy="list-name-input"
              />
              <div class="my-auto">
                <new-button
                  type="primary"
                  :disabled="isSaving || !canCreateList"
                  @click="createList"
                  data-cy="create-button"
                  >Create</new-button
                >
              </div>
            </div>
          </div>
          <div class="ml-auto flex gap-x-2">
            <new-button @click="close" :disabled="isSaving" type="secondary" data-cy="cancel-button"> Cancel </new-button>
            <new-button @click="updateCampaign" :disabled="isSaving" data-cy="update-button"> Update </new-button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
  import { mapGetters } from 'vuex';
  import SwalModalMixinVue from '@/views/Mixins/SwalModalMixin.vue';
  import { v4 as uuidv4 } from 'uuid';
  import dao from '@/dao';

  export default {
    data() {
      return {
        isSaving: false,
        lists: [],
        newListName: '',
        isAddNewListEnabled: false,
      };
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      canCreateList() {
        return (
          this.newListName &&
          !this.userProfile.autoDMTags.some(({ name }) => name === this.newListName)
        );
      },
      canUpdate() {
        return this.lists.length > 0;
      },
    },
    created() {
      this.lists = [...this.selectedLists];
    },
    watch: {
      selectedLists(val) {
        this.lists = [...val];
      },
    },
    methods: {
      resetState() {
        this.newListName = '';
        this.lists = [];
        this.isAddNewListEnabled = false;
      },
      async updateCampaign() {
        try {
          this.isSaving = true;
          const isPrivateCampaign = this.type === 'campaign';
          await dao.updateAutoDMTags(this.campaignId, this.lists, isPrivateCampaign);
          this.$notify({ type: 'success', message: `AutoDM ${this.type} updated successfully!` });
          this.$emit('update-lists', this.lists);
        } catch {
          this.swalModal({
            title: 'Error',
            html: `An error has occurred while trying to update the AutoDM ${this.type}.`,
            type: 'warning',
          });
        } finally {
          this.isSaving = false;
          this.resetState();
          this.close();
        }
      },
      async createList() {
        try {
          const listData = {
            name: this.newListName,
            id: uuidv4(),
          };
          this.isSaving = true;
          await dao.userProfile.addAutoDMTag(this.userProfile.uid, listData);
          this.$notify({ type: 'success', message: 'List added successfully!' });
        } catch {
          this.swalModal({
            title: 'Error',
            text: 'Something went wrong while adding list. Please try again later.',
            type: 'warning',
          });
        } finally {
          this.isSaving = false;
          this.newListName = '';
        }
      },
      close() {
        this.$emit('close');
      },
    },
    mixins: [SwalModalMixinVue],
    name: 'new-edit-lists-modal',
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      campaignId: {
        type: String,
        required: true,
      },
      type: {
        type: String,
        required: true,
      },
      selectedLists: {
        type: Array,
        required: true,
      },
    },
  };
</script>
